@import '@fontsource/work-sans/300.css';
@import '@fontsource/work-sans/400.css';
@import '@fontsource/work-sans/500.css';
@import '@fontsource/work-sans/600.css';
@import './antd.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply flex grow flex-col;
}

body {
  @apply m-0 font-sans leading-normal antialiased [overflow-wrap:anywhere];
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}
